<template>
  <el-card shadow="never">
    <el-image v-if="data.url" fit="contain" :src="data.url" />
    <div class="logo-label" v-else>
      {{ data.label }}
    </div>
    <div class="wrapper">
      <div class="item">
        <div class="label">店铺数</div>
        <div class="num">{{ shopCount }}</div>
      </div>
      <div class="item">
        <div class="label">已授权</div>
        <div class="num">{{ authorizeCount }}</div>
      </div>
      <div class="item">
        <div class="label">未授权</div>
        <div class="num warning">{{ noAuthorizeCount }}</div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  computed: {
    shopCount() {
      return this.data?.statistics?.shopCount || 0
    },
    authorizeCount() {
      return this.data?.statistics?.authorizeCount || 0
    },
    noAuthorizeCount() {
      return this.data?.statistics?.noAuthorizeCount || 0
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card {
  padding: 20px 16px;
  text-align: center;
  height: 100%;

  ::v-deep {
    .el-card__body {
      @include vCenter;
      justify-content: space-between;
      padding: 0;
      height: 100%;
    }
  }

  .el-image,
  .logo-label {
    flex: 0 0 26px;
    height: 26px;
    margin-bottom: 25px;
  }
  .logo-label {
    height: 19px;
    font-size: 20px;
    font-weight: 400;
    color: $color-primary;
  }

  .wrapper {
    @include vCenter;
    justify-content: space-between;
    flex: 1;
    .item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .label {
        font-size: 14px;
        color: #495060;
      }
      .num {
        color: $color-primary;
        font-size: 18px;
        font-weight: 500;
      }
      .warning {
        color: #ff4909;
      }
    }
  }
}
</style>
